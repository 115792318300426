<!-- notifications -->
<template>
  <div class="section-nopad">
    <Section :title="title" :hasFooter="false">
      <ul class="cardcontent-info">
        <li v-for="(item, index) in data" :key="index">
          <div class="lable">{{ item.label }}</div>
          <i class="iconfont iconedit" @click="handleEdit(item)"></i>
        </li>
      </ul>
    </Section>
    <Section :title="title2" :hasFooter="false">
      <ul class="cardcontent-info">
        <li v-for="(item, index) in data2" :key="index">
          <div class="lable">{{ item.label }}</div>
          <i class="iconfont iconedit" @click="handleEdit(item)"></i>
        </li>
      </ul>
    </Section>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      title: "Students",
      title2: "Contacts",
      data: [
        { label: "Student ID", value: "studentId" },
        { label: "Gender", value: "gender" },
        { label: "Race", value: "race" },
        { label: "Programme", value: "programme" },
        { label: "Primary Language", value: "primaryLanguage" },
        { label: "Mother Tongue", value: "motherTongue" },
      ],
      data2: [
        { label: "Nationality", value: "nationality" },
        { label: "ID type", value: "idType" },
        { label: "Relationship", value: "relationship" },
      ],
    };
  },
  methods: {
    handleEdit(item) {
      if (item.value === "studentId") {
        console.log(item.value);
        this.$router.push({
          name: "settingStudentId",
          query: {
            moduleType: "studentId",
            moduleName: "student",
          },
        });
        return false;
      }
      this.$router.push({
        name: "tablePage",
        query: {
          title: item.label,
          moduleType: item.value,
          moduleName: "student",
          activeIndex: "settingStudents",
        },
      });
    },
  },
  created() {},
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
</style>